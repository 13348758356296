import { Sheet, Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Item from "../../../../../components/item/item";

export default function OrderItem({ order, orderItem }) {
  const { items, variants, orderItems } = useSelector(
    (state) => state.restaurant
  );

  const [item, setItem] = useState(null);
  const [variant, setVariant] = useState(null);

  useEffect(() => {
    const variant = variants.filter((i) => i.id === orderItem.item_variant)[0];
    setVariant(variant);
    const item = items.filter((i) => i.id === variant.item)[0];
    setItem(item);
  }, [orderItem, items, variants, orderItems]);

  return (
    <Sheet
      sx={{
        backgroundColor: "#fff",
        display: "flex",
      }}
    >
      <Stack px={1.5} py={0.5} spacing={1} width="100%">
        {item && (
          <Item
            order={order}
            item={item}
            variant={variant}
            orderItem={orderItem}
            isOrderItem
          />
        )}
      </Stack>
    </Sheet>
  );
}
