import { createSlice } from "@reduxjs/toolkit";

const getDefaultWishlist = () => {
  let wishlist = [];
  const found = localStorage.getItem("wishlist");
  if (found) {
    try {
      const parsedFound = JSON.parse(found);
      wishlist = parsedFound;
    } catch (error) {}
  }
  return wishlist;
};

const defaultFilters = {
  isVeg: null, // null -> both, true -> veg, false -> non-veg
  wishlistedOnly: false,
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: {
    fetchedRestaurant: false,

    restaurant: {},
    table: null,

    tableTags: [],

    itemTags: [],
    itemCategories: [],
    items: [],
    variants: [],

    filters: defaultFilters,

    wishlist: getDefaultWishlist(),

    session: null,
    orders: [],
    orderItems: [],
  },
  reducers: {
    setFetchedRestaurant: (state, { payload }) => {
      state.fetchedRestaurant = payload;
    },

    setTableTags: (state, { payload }) => {
      state.tableTags = payload;
    },

    setTable: (state, { payload }) => {
      state.table = payload;
    },

    setItemTags: (state, { payload }) => {
      state.itemTags = payload;
    },

    setRestaurant: (state, { payload }) => {
      state.restaurant = payload;
    },

    setItemCategories: (state, { payload }) => {
      state.itemCategories = payload;
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
    setVariants: (state, { payload }) => {
      state.variants = payload;
    },

    setFilters: (state, { payload }) => {
      state.filters = payload;
    },
    resetFilters: (state) => {
      state.filters = defaultFilters;
    },

    setWishlist: (state, { payload }) => {
      state.wishlist = payload;
    },

    setSession: (state, { payload }) => {
      state.session = payload;
    },
    setOrders: (state, { payload }) => {
      state.orders = payload;
    },
    setOrderItems: (state, { payload }) => {
      state.orderItems = payload;
    },
  },
});

export const {
  setFetchedRestaurant,

  setItemTags,

  setRestaurant,

  setTable,
  setTableTags,

  setItemCategories,
  setItems,
  setVariants,

  setFilters,
  resetFilters,

  setWishlist,

  setSession,
  setOrders,
  setOrderItems,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;
