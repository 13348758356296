import { useState } from "react";

import { Add, Circle, Remove } from "@mui/icons-material";
import {
  Button,
  Container,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/joy";
import CartOperationVariant from "./cartOperationVariant";
import { useNavigate, useParams } from "react-router-dom";
export default function CartOperationVariants({
  item,
  order,
  addToCart,
  sending,
  optionCount,
  inCartCount,
  itemVariants,
  removeFromCart,
}) {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const { restaurantId, tableId } = useParams();

  return (
    <>
      {optionCount ? (
        <Typography
          level="title-sm"
          textColor="neutral.400"
          onClick={() => setOpen(true)}
          sx={{
            cursor: "pointer",
          }}
        >
          {optionCount} options
        </Typography>
      ) : inCartCount ? (
        <>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            <Remove />
          </IconButton>
          <Typography
            level="body-xs"
            minWidth="16px"
            textAlign="center"
            fontWeight={700}
          >
            {inCartCount}
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            <Add />
          </IconButton>
        </>
      ) : (
        <Button
          size="sm"
          color="neutral"
          startDecorator={<Add />}
          onClick={() => setOpen(true)}
        >
          ADD
        </Button>
      )}
      <Drawer
        open={open}
        onClose={() => setOpen(!open)}
        anchor="bottom"
        size="sm"
      >
        <Container
          maxWidth="xs"
          sx={{
            height: "100%",
          }}
        >
          <Stack spacing={1} justifyContent="space-between" height="100%">
            <Stack py={3} spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Circle
                  sx={{
                    fontSize: "12px",
                    color: item.is_veg ? "#00B620" : "#F90202",
                  }}
                />
                <Typography level="title-md" fontWeight={700}>
                  {item.name}
                </Typography>
              </Stack>
              {itemVariants.map((variant, index) => (
                <CartOperationVariant
                  variant={variant}
                  order={order}
                  key={index}
                  addToCart={addToCart}
                  removeFromCart={removeFromCart}
                  sending={sending}
                />
              ))}
            </Stack>
            <Stack direction="row" spacing={2} py={2}>
              <Button
                fullWidth
                size="sm"
                color="neutral"
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
              <Button
                fullWidth
                size="sm"
                color="neutral"
                variant="solid"
                onClick={() =>
                  navigate(`/${restaurantId}/table/${tableId}/cart`)
                }
              >
                Go to Cart
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Drawer>
    </>
  );
}
