import { Stack, Typography } from "@mui/joy";

import ios_camera from "../../assets/images/ios-camera.jpg";
import apple_logo from "../../assets/images/apple-logo.jpg";
import google_lens from "../../assets/images/google-lens.jpg";
import android_logo from "../../assets/images/android-logo.jpg";

export default function Home() {
  const Image = ({ src, alt, style }) => (
    <img
      src={src}
      alt={alt}
      style={{
        height: "24px",
        width: "24px",
        ...style,
      }}
    />
  );

  return (
    <Stack minHeight="80vh" justifyContent="center" spacing={1.5}>
      <Stack direction="row" justifyContent="center" alignItems="center" pb={4}>
        <Typography level="title-lg">Please scan the QR</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography level="body-xs">Use Camera App</Typography>
        <Image alt="iOS Camera App" src={ios_camera} />
        <Typography level="body-xs">in iPhone</Typography>
        <Image
          alt="iOS Camera App"
          src={apple_logo}
          style={{
            width: "20px",
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Typography level="body-xs">Use Google Lens</Typography>
        <Image
          alt="iOS Camera App"
          src={google_lens}
          style={{
            height: "20px",
            width: "20px",
          }}
        />
        <Typography level="body-xs">in Android</Typography>
        <Image alt="iOS Camera App" src={android_logo} />
      </Stack>
    </Stack>
  );
}
