import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate, useParams } from "react-router-dom";

import Loading from "../loading";

import {
  deleteUserCredentials,
  getAuthorizationHeader,
  getUserCredentials,
} from "../../utils/helpers";
import { authenticate, deAuthenticate } from "../../redux/auth";
import axios from "axios";
import { DOMAIN } from "../../utils/config";
import { setSession, setOrderItems, setOrders } from "../../redux/restaurant";
import { setNotify } from "../../redux/utils";

export default function Protected() {
  const [loaded, setLoaded] = useState(false);

  const { restaurant, fetchedRestaurant, table, session } = useSelector(
    (state) => state.restaurant
  );

  const { restaurantId, tableId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadSession = useCallback(() => {
    Promise.all([
      axios({
        method: "GET",
        url: `${DOMAIN}/customer-orders/session/`,
        headers: getAuthorizationHeader(restaurantId),
      }),
      axios({
        method: "GET",
        url: `${DOMAIN}/customer-orders/`,
        headers: getAuthorizationHeader(restaurantId),
      }),
      axios({
        method: "GET",
        url: `${DOMAIN}/customer-orders/items/`,
        headers: getAuthorizationHeader(restaurantId),
      }),
    ])
      .then(([sessionRes, ordersRes, orderItemsRes]) => {
        dispatch(setSession(sessionRes.data));
        dispatch(setOrders(ordersRes.data));
        dispatch(setOrderItems(orderItemsRes.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteUserCredentials(restaurantId);
          dispatch(
            setNotify({
              open: true,
              action: "Logged Out!",
              severity: "danger",
              autoHideDuration: 3000,
              vertical: "bottom",
              horizontal: "center",
            })
          );
          navigate(`/${restaurantId}/login?tableId=${tableId}`);
        } else {
          console.error(err);
        }
      });
  }, [dispatch, restaurantId, tableId, navigate]);

  useEffect(() => {
    const { accessToken } = getUserCredentials(restaurantId);
    if (accessToken) {
      dispatch(authenticate({ access_token: accessToken }));
      loadSession();
    } else {
      dispatch(deAuthenticate());
      navigate(`/${restaurant.uid}/login?tableId=${tableId}`);
    }
    setLoaded(true);
  }, [restaurant.uid, dispatch, navigate, tableId, loadSession, restaurantId]);

  return (
    <Loading
      loading={!loaded || !fetchedRestaurant || (tableId && !table) || !session}
      minHeight={"100vh"}
    >
      <Outlet />
    </Loading>
  );
}
