import { useState } from "react";

import { useDispatch } from "react-redux";

import { Stack, Button, Modal, Typography, Sheet } from "@mui/joy";

import axios from "axios";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";
import { useParams } from "react-router-dom";

export default function PlaceOrder({ orderId, seq_no }) {
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  const handleRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/place/`,
      headers: getAuthorizationHeader(restaurantId),
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        setSending(false);
        setOpen(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <>
      <Button color="neutral" size="sm" onClick={() => setOpen(true)}>
        Place Order
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: "300px",
            width: "100%",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography level="title-sm">
                Place Order <b>#{seq_no}</b>
              </Typography>
              <Typography level="body-xs" textColor="neutral.400">
                You can please the order with the added items. Please feel free
                to order again.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                disabled={sending}
                size="sm"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color="neutral"
                disabled={sending}
                size="sm"
                onClick={handleRequest}
              >
                Place Order
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}
