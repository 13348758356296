import { useSelector } from "react-redux";

import { Stack } from "@mui/joy";

import Item from "./item";
import { useEffect, useState } from "react";

export default function ItemList({ category }) {
  const { items, variants, filters, wishlist, orders } = useSelector(
    (state) => state.restaurant
  );

  const [loaded, setLoaded] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);

  useEffect(() => {
    let itemList = items.filter((i) => i.category === category.id);

    if (filters.isVeg === true) {
      itemList = itemList.filter((i) => i.is_veg);
    } else if (filters.isVeg === false) {
      itemList = itemList.filter((i) => !i.is_veg);
    }

    if (filters.wishlistedOnly) {
      const wishlistedVariants = variants.filter((i) =>
        wishlist.includes(i.id)
      );
      const tempWishlistedItemIds = wishlistedVariants.map((i) => i.item);
      const wishlistedItemIds = [...new Set(tempWishlistedItemIds)];

      itemList = itemList.filter((i) => wishlistedItemIds.includes(i.id));
    }

    setCategoryItems(itemList.sort((a, b) => a.id - b.id));
    setLoaded(true);
  }, [
    category.id,
    filters.isVeg,
    filters.wishlistedOnly,
    orders,
    items,
    loaded,
    variants,
    wishlist,
  ]);

  return (
    <Stack spacing={2} pb={2}>
      {categoryItems.map((item, index) => (
        <Item key={index} item={item} />
      ))}
    </Stack>
  );
}
