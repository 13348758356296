import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../redux/restaurant";

import { Link, useParams } from "react-router-dom";

import { IconButton, Stack } from "@mui/joy";

import {
  Favorite,
  FavoriteBorder,
  ShoppingCartOutlined,
} from "@mui/icons-material";

import BrandingSm from "../branding/BrandingSm";
import CallService from "./callService";

export default function Header() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { restaurant, filters } = useSelector((state) => state.restaurant);

  const { tableId } = useParams();

  const dispatch = useDispatch();

  const HeaderActions = () => (
    <Stack spacing={1} direction="row" pr={1}>
      {isAuthenticated ? (
        <>
          <CallService />
          <IconButton
            size="sm"
            component={Link}
            to={`/${restaurant.uid}/table/${tableId}/cart`}
          >
            <ShoppingCartOutlined />
          </IconButton>
        </>
      ) : (
        <IconButton
          size="sm"
          variant="plain"
          onClick={() => {
            dispatch(
              setFilters({
                ...filters,
                wishlistedOnly: !filters.wishlistedOnly,
              })
            );
          }}
        >
          {filters.wishlistedOnly ? <Favorite /> : <FavoriteBorder />}
        </IconButton>
      )}
    </Stack>
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1.5}
      px={1}
    >
      <BrandingSm />
      <HeaderActions />
    </Stack>
  );
}
