import { Stack, Typography } from "@mui/joy";
import { useSelector } from "react-redux";
import Order from "./order";

export default function OrderList() {
  const { orders } = useSelector((state) => state.restaurant);

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: "#f6f6f6",
      }}
    >
      {orders.length ? (
        orders.map((order, index) => (
          <Order
            order={order}
            index={index}
            seq_no={orders.length - index}
            key={index}
          />
        ))
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          minHeight="75vh"
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Typography level="body-xs" textColor="neutral.400">
            No Orders Placed!
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
