import { Stack } from "@mui/joy";
import BrandingLg from "../../components/branding/BrandingLg";
import PoweredBy from "../../components/smartdinein/poweredBy";
import Navigation from "./navigation";

export default function Restaurant() {
  return (
    <Stack minHeight="100vh" justifyContent="space-between" alignItems="center">
      <BrandingLg hideSubtitle />
      <Navigation />
      <PoweredBy />
    </Stack>
  );
}
