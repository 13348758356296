import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/joy";

import BrandingLg from "../../../../components/branding/BrandingLg";
import PoweredBy from "../../../../components/smartdinein/poweredBy";
import { checkIfSubscribedTo, PLAN } from "../../../../utils/config";

import BookTableForm from "./bookTableForm";
import JoinTableForm from "./joinTableForm";

export default function Auth() {
  const { restaurant, table } = useSelector((state) => state.restaurant);

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      if (!checkIfSubscribedTo(restaurant.plan_code, PLAN.PRO)) {
        navigate(`/${restaurant.uid}/menu`);
      }
      setLoaded(true);
    }
  }, [loaded, restaurant.plan_code, navigate, restaurant.uid]);

  return (
    <Stack minHeight="100vh" justifyContent="space-between" alignItems="center">
      <BrandingLg />
      {table.is_already_booked ? <JoinTableForm /> : <BookTableForm />}
      <PoweredBy />
    </Stack>
  );
}
