import { Add, Circle, Remove } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrency } from "../../utils/config";

export default function CartOperationVariant({
  variant,
  order,
  addToCart,
  removeFromCart,
  sending,
}) {
  const { restaurant, orderItems } = useSelector((state) => state.restaurant);

  const [inCartCount, setInCartCount] = useState(0);

  useEffect(() => {
    let counter = 0;
    const activeOrderItems = orderItems.filter(
      (i) => i.order === order.id && i.item_variant === variant.id
    );
    if (activeOrderItems.length) {
      const activeOrderItem = activeOrderItems[0];
      counter = activeOrderItem.qty;
    }
    setInCartCount(counter);
  }, [orderItems, order.id, variant.id]);

  return (
    <Stack spacing={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography level="title-sm" fontWeight={700}>
            {variant.name}
          </Typography>
          <Circle
            sx={{
              height: "5px",
              width: "5px",
              color: "GrayText",
            }}
          />
          <Typography level="title-sm">
            {getCurrency(restaurant.currency)}
            {variant.price}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {inCartCount ? (
            <>
              <IconButton
                size="sm"
                variant="outlined"
                disabled={sending}
                onClick={() => removeFromCart(variant.id)}
              >
                <Remove />
              </IconButton>
              <Typography
                level="body-xs"
                minWidth="16px"
                textAlign="center"
                fontWeight={700}
              >
                {inCartCount}
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                disabled={sending}
                onClick={() => addToCart(variant.id)}
              >
                <Add />
              </IconButton>
            </>
          ) : (
            <Button
              size="sm"
              color="neutral"
              startDecorator={<Add />}
              disabled={sending}
              onClick={() => addToCart(variant.id)}
            >
              ADD
            </Button>
          )}
        </Stack>
      </Stack>
      <Typography level="body-xs" color="neutral">
        {variant.description}
      </Typography>
    </Stack>
  );
}
