import { Stack } from "@mui/joy";

import Header from "../../../../components/header";
import PoweredBy from "../../../../components/smartdinein/poweredBy";

import CartActions from "./cartActions";
import OrderList from "./orders/orderList";

export default function Cart() {
  return (
    <Stack justifyContent="space-between" minHeight="95vh">
      <Stack>
        <Header />
        <CartActions />
        <OrderList />
      </Stack>
      <PoweredBy />
    </Stack>
  );
}
