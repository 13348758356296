import { MenuBook, TableBar } from "@mui/icons-material";
import { Card, Stack, Typography } from "@mui/joy";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Navigation() {
  const { restaurant } = useSelector((state) => state.restaurant);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      pb={15}
    >
      <Card
        sx={{
          minWidth: "90px",
          textDecoration: "none",
        }}
        component={Link}
        to={`/${restaurant.uid}/menu`}
      >
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <MenuBook
            sx={{
              fontSize: "50px",
            }}
          />
          <Typography level="body-xs">View Menu</Typography>
        </Stack>
      </Card>
      <Card
        sx={{
          minWidth: "90px",
          textDecoration: "none",
        }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <TableBar
            fontSize="large"
            sx={{
              fontSize: "50px",
              color: "neutral.400",
            }}
          />
          <Typography level="body-xs" textColor="neutral.400">
            Book a Table
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
}
