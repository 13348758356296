import { createSlice } from "@reduxjs/toolkit";

const defaultNotify = {
  open: false,
  action: null,
  severity: null,
  autoHideDuration: 3000,
  vertical: "bottom",
  horizontal: "center",
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    notify: defaultNotify,
  },
  reducers: {
    setNotify: (state, { payload }) => {
      state.notify = {
        ...defaultNotify,
        ...payload,
      };
    },
    resetNotify: (state) => {
      state.notify = defaultNotify;
    },
  },
});

export const { setNotify, resetNotify } = utilsSlice.actions;

export default utilsSlice.reducer;
