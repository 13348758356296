import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  setFetchedRestaurant,
  setItemCategories,
  setItems,
  setItemTags,
  setRestaurant,
  setVariants,
  setTable,
} from "../../redux/restaurant";

import { Outlet, useParams, useSearchParams } from "react-router-dom";

import Loading from "../loading";
import { DOMAIN } from "../../utils/config";

import axios from "axios";
import { getAuthorization } from "../../utils/helpers";

export default function Restaurant() {
  const { fetchedRestaurant } = useSelector((state) => state.restaurant);

  const { restaurantId, tableId } = useParams();

  const [searchParams] = useSearchParams();
  const searchTableId = searchParams.get("tableId");

  const dispatch = useDispatch();

  const setupRestaurant = useCallback(() => {
    if (!fetchedRestaurant) {
      const fetchOnlyRestaurantBasic =
        (searchTableId || tableId) && !getAuthorization(restaurantId);

      Promise.all([
        axios({
          method: "GET",
          url: `${DOMAIN}/public/organizations`,
          params: {
            uid: restaurantId,
            basic_only: fetchOnlyRestaurantBasic ? 1 : 0,
          },
        }),
        !fetchOnlyRestaurantBasic
          ? axios({
              method: "GET",
              url: `${DOMAIN}/public/menus/tags`,
            })
          : Promise.resolve(),
        searchTableId || tableId
          ? axios({
              method: "GET",
              url: `${DOMAIN}/public/tables/`,
              params: {
                organization_uid: restaurantId,
                table_id: searchTableId || tableId,
              },
            })
          : Promise.resolve(),
      ])
        .then((res) => {
          const [restaurantsRes, menuTagsRes, tableRes] = res;

          const { organization, item_categories, items, variants } =
            restaurantsRes.data;

          if (tableRes) dispatch(setTable(tableRes.data));
          if (menuTagsRes) dispatch(setItemTags(menuTagsRes.data));

          dispatch(setRestaurant(organization));
          dispatch(setItemCategories(item_categories));
          dispatch(setItems(items));
          dispatch(setVariants(variants));

          dispatch(setFetchedRestaurant(true));
        })
        .catch((err) => console.error(err));
    }
  }, [restaurantId, fetchedRestaurant, dispatch, searchTableId, tableId]);

  useEffect(() => {
    setupRestaurant();
  }, [dispatch, setupRestaurant]);

  return (
    <Loading loading={!fetchedRestaurant} minHeight={"100vh"}>
      <Outlet />
    </Loading>
  );
}
