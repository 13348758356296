import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Input, Stack, Typography } from "@mui/joy";

import { Edit } from "@mui/icons-material";

import { DOMAIN } from "../../../../utils/config";
import { saveUserCredentials } from "../../../../utils/helpers";

import axios from "axios";
import { setNotify } from "../../../../redux/utils";

export default function BookTableForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [accessToken, setAccessToken] = useState(null);

  const [codeSent, setCodeSent] = useState(false);
  const [sending, setSending] = useState(false);

  const { restaurant, table } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  const handleCodeRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/create-session/`,
      data: {
        organization_uid: restaurant.uid,
        table_id: table.id,
        country_code: "IN",
        phone,
        name,
      },
    })
      .then((res) => {
        if (res.data.skip_verification) {
          saveUserCredentials(res.data.access_token, restaurant.uid);
          window.location.replace(`/${restaurant.uid}/table/${table.id}`);
          return;
        }
        setAccessToken(res.data.access_token);
        setCodeSent(true);
        setSending(false);
      })
      .catch((err) => {
        setSending(false);
        console.error(err);
      });
  };

  const handleCodeSubmit = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/activate-session/`,
      headers: {
        "x-sd-clienttoken": accessToken,
      },
      data: {
        code,
      },
    })
      .then(() => {
        saveUserCredentials(accessToken, restaurant.uid);
        window.location.replace(`/${restaurant.uid}/table/${table.id}`);
      })
      .catch((err) => {
        let error_message = "Something went wrong!";
        try {
          let error_object = JSON.parse(err.request.response);
          error_message = error_object.message;
        } catch {}

        setSending(false);

        dispatch(
          setNotify({
            open: true,
            action: error_message,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <Stack alignItems="center" spacing={2} minWidth={280}>
      {table && (
        <Typography level="body-xs" component="p">
          You are booking <b>{table.name}</b>
        </Typography>
      )}
      <Input
        fullWidth
        required
        size="sm"
        variant="outlined"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        fullWidth
        required
        size="sm"
        type="number"
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      ></Input>
      {codeSent && (
        <Stack spacing={0.5} width="100%">
          <Input
            fullWidth
            size="sm"
            variant="soft"
            placeholder="Enter Booking Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Typography level="body-xs" textColor="neutral.400">
            Staff Member will provide you the Booking Code.
          </Typography>
        </Stack>
      )}
      <Stack direction="row" width="100%" spacing={1}>
        {codeSent && (
          <Button
            fullWidth
            size="sm"
            variant="plain"
            color="neutral"
            startDecorator={<Edit />}
            onClick={() => setCodeSent(false)}
          >
            Edit
          </Button>
        )}
        {codeSent ? (
          <Button
            fullWidth
            size="sm"
            color="neutral"
            onClick={handleCodeSubmit}
            disabled={sending}
          >
            Submit
          </Button>
        ) : (
          <Button
            fullWidth
            size="sm"
            color="neutral"
            onClick={handleCodeRequest}
            disabled={sending || !name.length || !phone.length}
          >
            {!table.skip_booking_code ? "Get Booking Code" : "View Menu"}
          </Button>
        )}
      </Stack>

      <Stack
        sx={{
          height: "20vh",
        }}
      ></Stack>
    </Stack>
  );
}
