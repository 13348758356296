import { useDispatch, useSelector } from "react-redux";

import { Chip, Stack, Typography } from "@mui/joy";

import { Circle } from "@mui/icons-material";
import { setFilters } from "../../../redux/restaurant";

export default function MenuFilters() {
  const { filters } = useSelector((state) => state.restaurant);
  const { isVeg } = filters;

  const dispatch = useDispatch();

  return (
    <Stack direction="row" alignItems="center" spacing={1} px={1} py={0.5}>
      <Chip
        color="neutral"
        size="sm"
        variant={isVeg === null ? "solid" : "outlined"}
        onClick={() => {
          dispatch(
            setFilters({
              ...filters,
              isVeg: null,
            })
          );
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={0.5}
          py={0.5}
          px={1}
        >
          <Typography
            level="body-xs"
            textColor={isVeg === null ? "neutral.50" : "neutral.500"}
            fontWeight={700}
          >
            All
          </Typography>
        </Stack>
      </Chip>
      <Chip
        color="neutral"
        size="sm"
        variant={isVeg ? "solid" : "outlined"}
        onClick={() => {
          dispatch(
            setFilters({
              ...filters,
              isVeg: true,
            })
          );
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={0.5}
          py={0.5}
          px={0.35}
        >
          <Circle sx={{ color: "#00B620" }} />
          <Typography
            level="body-xs"
            textColor={isVeg === true ? "neutral.50" : "neutral.500"}
            fontWeight={700}
          >
            veg
          </Typography>
        </Stack>
      </Chip>
      <Chip
        color="neutral"
        size="sm"
        variant={isVeg === false ? "solid" : "outlined"}
        onClick={() => {
          dispatch(
            setFilters({
              ...filters,
              isVeg: false,
            })
          );
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={0.5}
          py={0.5}
          px={0.35}
        >
          <Circle sx={{ color: "#F90202" }} />
          <Typography
            level="body-xs"
            textColor={isVeg === false ? "neutral.50" : "neutral.500"}
            fontWeight={700}
          >
            non-veg
          </Typography>
        </Stack>
      </Chip>
    </Stack>
  );
}
