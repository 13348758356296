import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Stack, Button, Modal, Typography, Sheet } from "@mui/joy";

import { ReceiptLong, StarBorder } from "@mui/icons-material";

import axios from "axios";
import { getAuthorizationHeader } from "../../utils/helpers";
import { DOMAIN } from "../../utils/config";
import { setNotify } from "../../redux/utils";
import { useParams } from "react-router-dom";
import { setSession } from "../../redux/restaurant";

export default function RequestBill() {
  const { restaurant, session } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  const handleRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/bill-request/`,
      headers: getAuthorizationHeader(restaurantId),
    })
      .then(() => {
        setSending(false);
        dispatch(
          setSession({
            ...session,
            is_requesting_bill: true,
          })
        );
        dispatch(
          setNotify({
            open: true,
            action: "Request Sent!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
        if (restaurant.google_maps_uri) {
          setSent(true);
        } else {
          setOpen(false);
        }
      })
      .catch((err) => {
        setSending(false);
        setOpen(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  const handleOpenGoogleMaps = () => {
    window.open(restaurant.google_maps_uri, "_blank");
  };

  const GoogleReviewModal = () => (
    <Sheet
      variant="outlined"
      sx={{
        maxWidth: "300px",
        width: "100%",
        borderRadius: "md",
        p: 3,
      }}
    >
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography level="title-sm">How was your 🤩 Experience?</Typography>
          <Typography level="body-xs" textColor="neutral.400">
            Give us a ⭐️ 5-star Rating on Google if you enjoyed our services.
            And, share your experience in comments.
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            {[1, 2, 3, 4, 5].map((index) => (
              <StarBorder key={index} onClick={handleOpenGoogleMaps} />
            ))}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1.5}
        >
          <Button
            fullWidth
            color="neutral"
            variant="outlined"
            disabled={sending}
            size="sm"
            onClick={() => {
              setSent(false);
              setOpen(false);
            }}
          >
            Close
          </Button>
          <Button
            fullWidth
            color="neutral"
            disabled={sending}
            size="sm"
            onClick={handleOpenGoogleMaps}
          >
            Post a Review
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );

  return (
    <>
      <Button
        color="neutral"
        size="sm"
        startDecorator={<ReceiptLong />}
        disabled={session.is_requesting_bill}
        onClick={() => setOpen(true)}
      >
        {session.is_requesting_bill ? "Requested" : "Bring Bill"}
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {sent && Boolean(restaurant.google_maps_uri) ? (
          <GoogleReviewModal />
        ) : (
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: "300px",
              width: "100%",
              borderRadius: "md",
              p: 3,
            }}
          >
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Typography level="title-sm">Do you Need Bill?</Typography>
                <Typography level="body-xs" textColor="neutral.400">
                  Once requested, you will not be able to place more orders. If
                  you wish to do so, you will have to scan the QR Code again.
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1.5}
              >
                <Button
                  fullWidth
                  color="neutral"
                  variant="outlined"
                  disabled={sending}
                  size="sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  color="neutral"
                  disabled={sending}
                  size="sm"
                  onClick={handleRequest}
                >
                  Bring Bill
                </Button>
              </Stack>
            </Stack>
          </Sheet>
        )}
      </Modal>
    </>
  );
}
