import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Stack, Tooltip, Typography } from "@mui/joy";

import { Circle } from "@mui/icons-material";

import AddItem from "./addItem";
import { getTagIcon } from "../../utils/helpers";
import CartOperation from "./cartOperation";
import { getCurrency } from "../../utils/config";

export default function Item({ order, item, variant, orderItem, isOrderItem }) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { restaurant, variants, itemTags, orderItems, orders } = useSelector(
    (state) => state.restaurant
  );

  const [fullDescription, setFullDescription] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [itemVariants, setItemVariants] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    let tempItemVariants = variants.filter((i) => i.item === item.id);
    setItemVariants(tempItemVariants.sort((a, b) => a.seq_no - b.seq_no));

    let tags = [];
    item.tags.forEach((tId) => {
      tags = [...tags, ...itemTags.filter((i) => i.id === tId)];
    });
    setSelectedTags(tags);
    setLoaded(true);
  }, [
    variants,
    item.id,
    setItemVariants,
    itemTags,
    item.tags,
    orderItems,
    orders,
  ]);

  return (
    loaded && (
      <Stack spacing={0.25} py={0.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack spacing={0.25}>
            <Stack direction="row" spacing={0.25}>
              {selectedTags.length
                ? selectedTags.map((t, index) => (
                    <Tooltip title={t.name} key={index} size="sm">
                      {getTagIcon(t, { fontSize: "small", key: index })}
                    </Tooltip>
                  ))
                : null}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={0.5}
            >
              <Circle
                sx={{
                  fontSize: "12px",
                  color: item.is_veg ? "#00B620" : "#F90202",
                }}
              />
              <Typography level="title-sm" fontWeight={700}>
                {item.name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.75}>
              <Typography level="title-sm">
                {getCurrency(restaurant.currency)}
                {itemVariants.length ? itemVariants[0].price : ""}
              </Typography>
              {isOrderItem && variant ? (
                <Stack direction="row" alignItems="center" spacing={0.65}>
                  <Circle
                    sx={{
                      height: "5px",
                      width: "5px",
                      color: "GrayText",
                    }}
                  />
                  <Typography level="title-sm" textColor="neutral.400">
                    {variant.name}
                  </Typography>
                </Stack>
              ) : itemVariants.length > 1 ? (
                <Stack direction="row" alignItems="center" spacing={0.65}>
                  <Circle
                    sx={{
                      height: "5px",
                      width: "5px",
                      color: "GrayText",
                    }}
                  />
                  {isAuthenticated ? (
                    <CartOperation
                      optionCount={itemVariants.length}
                      item={item}
                      isOrderItem={isOrderItem}
                      orderItem={orderItem}
                    />
                  ) : (
                    <AddItem optionCount={itemVariants.length} item={item} />
                  )}
                </Stack>
              ) : null}
            </Stack>
          </Stack>
          {order?.placed_on ? (
            <Typography level="body-xs" textColor="neutral.400">
              x {orderItem.qty}
            </Typography>
          ) : isAuthenticated ? (
            <CartOperation
              item={item}
              isOrderItem={isOrderItem}
              orderItem={orderItem}
            />
          ) : (
            <AddItem item={item} />
          )}
        </Stack>
        {!isOrderItem && (
          <Typography level="body-xs" color="neutral">
            {fullDescription ? (
              <>
                {item.description}
                {"  "}
                <b
                  onClick={() => setFullDescription(false)}
                  style={{ cursor: "pointer" }}
                >
                  read less
                </b>
              </>
            ) : item.description.length > 100 ? (
              <>
                {item.description.slice(0, 100)}{" "}
                <b
                  onClick={() => setFullDescription(true)}
                  style={{ cursor: "pointer" }}
                >
                  read more..
                </b>
              </>
            ) : (
              item.description
            )}
          </Typography>
        )}
      </Stack>
    )
  );
}
