import { Stack, Typography } from "@mui/joy";
import React from "react";

export default function PoweredBy() {
  return (
    <Stack py={2} justifyContent="center" alignItems="center">
      <Typography level="body-xs" component="p">
        Powered By{" "}
        <Typography
          level="body-xs"
          fontWeight={700}
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => window.open("http://www.smartdine.in", "_blank")}
        >
          Smart Dine-in
        </Typography>
      </Typography>
    </Stack>
  );
}
