import { BrowserRouter, Routes, Route } from "react-router-dom";

// hoc
import Wrapper from "./hoc/wrapper";
import RestaurantWrapper from "./hoc/wrapper/restaurant";
import Protected from "./hoc/wrapper/protected";

// pages
import Home from "./pages/home";
import Restaurant from "./pages/restaurant";
import Menu from "./pages/restaurant/menu";
import Login from "./pages/restaurant/auth/login";
import Table from "./pages/restaurant/auth/table";
import Cart from "./pages/restaurant/auth/cart";

// utils
import urls from "./utils/urls.json";

export default function App() {
  const restaurantRoues = () => (
    <Route path={urls.restaurant.path} element={<RestaurantWrapper />}>
      <Route path={urls.restaurant.path} element={<Restaurant />}></Route>
      <Route
        path={urls.restaurant.children.menu.path}
        element={<Menu />}
      ></Route>
      <Route
        path={urls.restaurant.children.auth.children.login.path}
        element={<Login />}
      ></Route>

      <Route path={urls.restaurant.children.auth.path} element={<Protected />}>
        <Route
          path={urls.restaurant.children.auth.children.table.path}
          element={<Table />}
        ></Route>
        <Route
          path={
            urls.restaurant.children.auth.children.table.children.cart
              .absolutePath
          }
          element={<Cart />}
        ></Route>
      </Route>
    </Route>
  );

  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          {restaurantRoues()}
          <Route path={urls.home.path} element={<Home />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}
