import {
  ChildCare,
  Egg,
  FamilyRestroom,
  LocalFireDepartment,
  NoFood,
  PeopleOutline,
  Stars,
  WbSunny,
} from "@mui/icons-material";

export const getTagIcon = (tag, props) => {
  switch (tag.code) {
    case "KIDS_CHOICE":
      return <ChildCare {...props} />;
    case "BEST_SELLER":
      return <Stars {...props} />;
    case "SPICY":
      return <LocalFireDepartment {...props} />;
    case "NO_ONION_AND_GARLIC":
      return <NoFood {...props} />;
    case "CONTAINS_EGG":
      return <Egg {...props} />;
    case "KIDS_FRIENDLY":
      return <ChildCare {...props} />;
    case "FAMILY_FRIENDLY":
      return <FamilyRestroom {...props} />;
    case "COUPLE_FRIENDLY":
      return <PeopleOutline {...props} />;
    case "ROOF_TOP":
      return <WbSunny {...props} />;
    default:
      return <ChildCare {...props} />;
  }
};

export const getUserCredentials = (restaurantId) => {
  return { accessToken: localStorage.getItem(`${restaurantId}-access`) };
};

export const saveUserCredentials = (accessToken, restaurantId) => {
  localStorage.setItem(`${restaurantId}-access`, accessToken);
};

export const deleteUserCredentials = (restaurantId) => {
  localStorage.removeItem(`${restaurantId}-access`);
};

export const getAuthorization = (restaurantId) => {
  const data = getUserCredentials(restaurantId);
  return data["accessToken"];
};

export const getAuthorizationHeader = (restaurantId) => {
  return {
    "x-sd-clienttoken": getAuthorization(restaurantId),
  };
};
