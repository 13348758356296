import { IconButton, Sheet, Stack, Typography } from "@mui/joy";

import { ArrowDropDown, ArrowLeft } from "@mui/icons-material";
import OrderItemList from "../orderItem/orderItemList";
import { useEffect, useState } from "react";
import PlaceOrder from "./placeOrder";
import { useSelector } from "react-redux";

export default function Order({ order, seq_no, index }) {
  const { orderItems } = useSelector((state) => state.restaurant);

  const [expanded, setExpanded] = useState(index === 0);

  const [orderItemsCount, setOrderItemsCount] = useState(0);

  useEffect(() => {
    setOrderItemsCount(orderItems.filter((i) => i.order === order.id).length);
  }, [order.id, orderItems]);

  const CategoryHeader = () => (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography level="title-md">
        Order <b>#{seq_no}</b>
      </Typography>
      <IconButton size="sm" onClick={() => setExpanded(!expanded)}>
        {expanded ? <ArrowDropDown /> : <ArrowLeft />}
      </IconButton>
    </Stack>
  );

  return (
    <Stack>
      <Sheet
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Stack px={1.5} py={0.5} spacing={1}>
          <CategoryHeader />
          {expanded && <OrderItemList order={order} />}
          {index === 0 && !order.placed_on && orderItemsCount ? (
            <Stack pb={2}>
              <PlaceOrder orderId={order.id} seq_no={seq_no} />
            </Stack>
          ) : null}
        </Stack>
      </Sheet>
    </Stack>
  );
}
