import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Input, Stack, Typography } from "@mui/joy";

import { DOMAIN } from "../../../../utils/config";
import { saveUserCredentials } from "../../../../utils/helpers";

import axios from "axios";
import { setNotify } from "../../../../redux/utils";

export default function JoinTableForm() {
  const [code, setCode] = useState("");

  const [sending, setSending] = useState(false);

  const { restaurant, table } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  const handleCodeSubmit = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/join-session/`,
      data: {
        organization_uid: restaurant.uid,
        table_id: table.id,
        code,
      },
    })
      .then((res) => {
        saveUserCredentials(res.data.access_token, restaurant.uid);
        window.location.replace(`/${restaurant.uid}/table/${table.id}`);
      })
      .catch((err) => {
        let error_message = "Something went wrong!";
        try {
          let error_object = JSON.parse(err.request.response);
          error_message = error_object.message;
        } catch {}

        setSending(false);

        dispatch(
          setNotify({
            open: true,
            action: error_message,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <Stack alignItems="center" spacing={2} minWidth={280}>
      {table && (
        <Stack alignItems="center" spacing={1} pb={2}>
          <Typography level="body-xs" component="p">
            <b>{table.name}</b> is Already Booked
          </Typography>
          <Typography level="body-xs" textColor="neutral.400">
            You can join the table
            {!table.skip_booking_code && " by entering the Booking Code"}.
          </Typography>
        </Stack>
      )}
      {!table.skip_booking_code && (
        <Input
          fullWidth
          size="sm"
          variant="soft"
          placeholder="Enter Booking Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      )}

      <Button
        fullWidth
        size="sm"
        color="neutral"
        onClick={handleCodeSubmit}
        disabled={sending}
      >
        {table.skip_booking_code ? "Join" : "Submit"}
      </Button>

      <Stack
        sx={{
          height: "20vh",
        }}
      ></Stack>
    </Stack>
  );
}
