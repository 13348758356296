import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/joy";

export default function BrandingLg({ hideSubtitle }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="center"
      py={2}
      pt={5}
    >
      {restaurant.logo && (
        <img
          src={restaurant.logo}
          alt="logo"
          style={{
            borderRadius: "50%",
            height: "90px",
            width: "90px",
            border: "1px solid #e2e2e2",
          }}
        />
      )}
      <Stack spacing={0.5} justifyContent="center" alignItems="center">
        <Typography level="body-md" fontWeight={700}>
          {restaurant.name}
        </Typography>
        {!hideSubtitle && (
          <Typography level="body-xs" textColor="neutral.400">
            Reserve your table in just 2 easy steps.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
