import { configureStore } from "@reduxjs/toolkit";
import restaurant from "./restaurant";
import auth from "./auth";
import utils from "./utils";

export default configureStore({
  reducer: {
    auth,
    restaurant,
    utils,
  },
});
