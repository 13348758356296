import { useNavigate, useParams } from "react-router-dom";

import { ArrowBackIosNew } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/joy";
import RequestBill from "../../../../components/header/requestBill";

export default function CartActions() {
  const { restaurantId, tableId } = useParams();

  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      px={1}
      py={0.5}
    >
      <IconButton
        size="sm"
        onClick={() => navigate(`/${restaurantId}/table/${tableId}`)}
      >
        <ArrowBackIosNew />
      </IconButton>
      <RequestBill />
    </Stack>
  );
}
