import { IconButton, Sheet, Stack, Typography } from "@mui/joy";

import { ArrowDropDown, ArrowLeft } from "@mui/icons-material";
import ItemList from "../item/itemList";
import { useState } from "react";

export default function Category({ category, categoryRef }) {
  const [expanded, setExpanded] = useState(true);

  const CategoryHeader = () => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={0.5}
    >
      <Typography level="title-sm" fontWeight={700}>
        {category.name}
      </Typography>
      <IconButton size="sm" onClick={() => setExpanded(!expanded)}>
        {expanded ? <ArrowDropDown /> : <ArrowLeft />}
      </IconButton>
    </Stack>
  );

  return (
    <Stack ref={categoryRef}>
      <Sheet
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Stack px={1.5} py={0.5} spacing={1}>
          <CategoryHeader />
          {expanded && <ItemList category={category} />}
        </Stack>
      </Sheet>
    </Stack>
  );
}
