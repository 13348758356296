import { useState } from "react";

import { useDispatch } from "react-redux";

import { Stack, Button, Modal, Typography, Sheet, IconButton } from "@mui/joy";

import { RoomServiceOutlined } from "@mui/icons-material";

import axios from "axios";
import { getAuthorizationHeader } from "../../utils/helpers";
import { DOMAIN } from "../../utils/config";
import { setNotify } from "../../redux/utils";
import { useParams } from "react-router-dom";

export default function CallService() {
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  const handleRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/call-request/`,
      headers: getAuthorizationHeader(restaurantId),
    })
      .then(() => {
        setSending(false);
        setOpen(false);
        dispatch(
          setNotify({
            open: true,
            action: "Request Sent!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      })
      .catch((err) => {
        setSending(false);
        setOpen(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <>
      <IconButton size="sm" onClick={() => setOpen(true)}>
        <RoomServiceOutlined />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: "300px",
            width: "100%",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography level="title-sm">Do you need Assistance?</Typography>
              <Typography level="body-xs" textColor="neutral.400">
                Our staff member will reach out to you in a while. Please feel
                free to ask for assistance anytime.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                disabled={sending}
                size="sm"
                onClick={() => setOpen(false)}
              >
                No
              </Button>
              <Button
                fullWidth
                color="neutral"
                disabled={sending}
                size="sm"
                onClick={handleRequest}
              >
                Yes
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}
