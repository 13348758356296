import { useSelector } from "react-redux";

import {
  Stack,
  Typography,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
} from "@mui/joy";

import { RestaurantMenu } from "@mui/icons-material";

export default function MenuFab({ categoryRefs }) {
  const { itemCategories, items } = useSelector((state) => state.restaurant);

  const getItemCount = (category) => {
    return items.filter((item) => item.category === category.id).length;
  };

  const haveItems = (category) => {
    return Boolean(
      items.filter((item) => item.category === category.id).length
    );
  };

  const getCategories = () => {
    let categories = [];
    itemCategories.forEach((cat) => {
      if (haveItems(cat)) {
        categories = [...categories, cat];
      }
    });
    return categories.sort((a, b) => a.seq_no - b.seq_no);
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "sticky",
        bottom: "0px",
      }}
      pb={2}
    >
      <Dropdown>
        <MenuButton
          variant="solid"
          color="neutral"
          startDecorator={<RestaurantMenu />}
          sx={{
            borderRadius: "20px",
            minWidth: "150px",
          }}
        >
          Menu
        </MenuButton>
        <Menu
          size="lg"
          sx={{
            minWidth: "200px",
          }}
        >
          {getCategories().map((category, index) => (
            <MenuItem
              key={index}
              onClick={() => categoryRefs.current[index].scrollIntoView()}
            >
              <Typography level="body-xs">
                <b>{category.name}</b> ({getItemCount(category)})
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </Stack>
  );
}
