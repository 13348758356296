import { useRef } from "react";

import { useSelector } from "react-redux";

import { CircularProgress, Stack, Typography } from "@mui/joy";

import CategoryList from "../../../components/itemCategory/categoryList";
import PoweredBy from "../../../components/smartdinein/poweredBy";
import Header from "../../../components/header";

import MenuFilters from "./MenuFilters";

import MenuFab from "../../../components/menuFab";
import { RestaurantMenu } from "@mui/icons-material";

export default function Menu() {
  const categoryRefs = useRef([]);

  const { fetchedRestaurant, filters } = useSelector(
    (state) => state.restaurant
  );

  return (
    <Stack>
      {fetchedRestaurant ? (
        <>
          <Header />
          <MenuFilters />
          <CategoryList categoryRefs={categoryRefs} />
          {!filters.wishlistedOnly && <MenuFab categoryRefs={categoryRefs} />}
        </>
      ) : (
        <Stack
          minHeight="93vh"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <>
            <CircularProgress size="sm" color="neutral" />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}
            >
              <Typography level="body-xs">Loading</Typography>
              <RestaurantMenu fontSize="small" />
              <Typography level="body-xs">Menu for You</Typography>
            </Stack>
          </>
        </Stack>
      )}
      <PoweredBy />
    </Stack>
  );
}
