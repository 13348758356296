import { Stack, Typography } from "@mui/joy";
import { useSelector } from "react-redux";
import OrderItem from "./orderItem";
import { useEffect, useState } from "react";

export default function OrderItemList({ order }) {
  const { orderItems } = useSelector((state) => state.restaurant);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = orderItems.filter((i) => i.order === order.id && i.qty >= 1);
    setItems(items);
  }, [order.id, orderItems]);

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: "#f6f6f6",
      }}
    >
      {items.length ? (
        items.map((i, index) => (
          <OrderItem order={order} orderItem={i} key={index} />
        ))
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          minHeight="150px"
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Typography level="body-xs" textColor="neutral.400">
            Add Items!
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
