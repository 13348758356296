import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Drawer,
  Button,
  Stack,
  IconButton,
  Typography,
} from "@mui/joy";

import {
  ArrowBackIosNew,
  Circle,
  Favorite,
  FavoriteBorder,
} from "@mui/icons-material";
import { setWishlist } from "../../redux/restaurant";
import { getCurrency } from "../../utils/config";

export default function AddItem({ optionCount, item }) {
  const { restaurant, variants, wishlist } = useSelector(
    (state) => state.restaurant
  );

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const getVariants = () => {
    let itemVariants = variants.filter((i) => i.item === item.id);
    return itemVariants.sort((a, b) => a.seq_no - b.seq_no);
  };

  const isAnyInWishlist = (variants) => {
    let found = false;
    variants.forEach((variant) => {
      wishlist.forEach((item) => {
        if (item === variant.id) {
          found = true;
        }
      });
    });
    return found;
  };

  const addToWishlist = (variantsId) => {
    const updatedWishlist = [...wishlist, variantsId];
    dispatch(setWishlist(updatedWishlist));
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  };

  const removeFromWishlist = (variantsId) => {
    const updatedWishlist = wishlist.filter((i) => i !== variantsId);
    dispatch(setWishlist(updatedWishlist));
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  };

  const handleClickFavorite = (variant) => {
    const variantId = variant.id;

    if (isAnyInWishlist([variant])) {
      removeFromWishlist(variantId);
    } else {
      addToWishlist(variantId);
    }
  };

  const handleClickOpen = () => {
    const variants = getVariants(item);
    if (variants.length > 1) {
      setOpen(true);
    } else {
      handleClickFavorite(variants[0]);
    }
  };

  return (
    <>
      {optionCount ? (
        <Typography
          level="title-sm"
          textColor="neutral.400"
          onClick={handleClickOpen}
          sx={{
            cursor: "pointer",
          }}
        >
          {optionCount} options
        </Typography>
      ) : (
        <IconButton
          size="sm"
          variant={"plain"}
          color="neutral"
          onClick={handleClickOpen}
        >
          {isAnyInWishlist(getVariants()) ? <Favorite /> : <FavoriteBorder />}
        </IconButton>
      )}
      <Drawer
        open={open}
        onClose={() => setOpen(!open)}
        anchor="bottom"
        size="sm"
      >
        <Container
          maxWidth="xs"
          sx={{
            height: "100%",
          }}
        >
          <Stack spacing={1} justifyContent="space-between" height="100%">
            <Stack py={3} spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Circle
                  sx={{
                    fontSize: "12px",
                    color: item.is_veg ? "#00B620" : "#F90202",
                  }}
                />
                <Typography level="title-md" fontWeight={700}>
                  {item.name}
                </Typography>
              </Stack>
              {getVariants().map((variant, index) => (
                <Stack spacing={0} key={index}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography level="title-sm" fontWeight={700}>
                      {variant.name}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography level="title-sm" fontWeight={700}>
                        {getCurrency(restaurant.currency)}
                        {variant.price}
                      </Typography>
                      <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => handleClickFavorite(variant)}
                      >
                        {isAnyInWishlist([variant]) ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorder />
                        )}
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Typography level="body-xs" color="neutral">
                    {variant.description}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack direction="row" spacing={2} py={2}>
              <Button
                fullWidth
                size="sm"
                color="neutral"
                variant="outlined"
                startDecorator={<ArrowBackIosNew />}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Drawer>
    </>
  );
}
