import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setNotify } from "../../redux/utils";

import { useParams } from "react-router-dom";

import { Add, Remove } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/joy";

import { DOMAIN } from "../../utils/config";
import { getAuthorizationHeader } from "../../utils/helpers";

import axios from "axios";
import { setOrderItems, setOrders } from "../../redux/restaurant";
import CartOperationVariants from "./cartOperationVariants";

export default function CartOperation({
  item,
  orderItem,
  isOrderItem,
  optionCount,
}) {
  const { variants, orders, orderItems } = useSelector(
    (state) => state.restaurant
  );

  const [orderLoaded, setOrderLoaded] = useState(false);
  const [order, setOrder] = useState(null);

  const [loaded, setLoaded] = useState(false);
  const [itemVariants, setItemVariants] = useState([]);
  const [inCartCount, setInCartCount] = useState(0);

  const [sending, setSending] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setItemVariants(variants.filter((i) => i.item === item.id));
    setLoaded(true);
  }, [loaded, variants, orders, item]);

  useEffect(() => {
    if (!orderLoaded) {
      const foundOrders = orders.filter(
        (i) =>
          i.placed_on === null &&
          i.served_on === null &&
          i.completed_on === null
      );
      if (foundOrders.length) {
        setOrder(foundOrders[0]);
      }
      setOrderLoaded(true);
    }
  }, [orderLoaded, orders]);

  useEffect(() => {
    let counter = 0;
    if (loaded && order) {
      if (isOrderItem && orderItem) {
        counter = orderItem.qty;
      } else {
        const activeOrderItems = orderItems.filter((i) => i.order === order.id);
        for (let i = 0; i < activeOrderItems.length; i++) {
          const activeOrderItem = activeOrderItems[i];
          for (let j = 0; j < itemVariants.length; j++) {
            const variant = itemVariants[j];
            if (activeOrderItem.item_variant === variant.id) {
              counter = counter + activeOrderItem.qty;
            }
          }
        }
      }
      setInCartCount(counter);
    }
  }, [loaded, order, itemVariants, orderItems, orders, isOrderItem, orderItem]);

  const addToCart = (variantId) => {
    // const variantId = itemVariants[0].id;
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/add-to-cart/`,
      headers: getAuthorizationHeader(restaurantId),
      data: {
        variant_id: variantId,
      },
    })
      .then((res) => {
        const { order, order_item } = res.data;

        if (order) dispatch(setOrders([...orders, order]));

        const orderItemFound = orderItems.filter((i) => i.id === order_item.id);
        if (orderItemFound.length) {
          dispatch(
            setOrderItems(
              orderItems.map((i) => {
                if (i.id === order_item.id) {
                  return order_item;
                }
                return i;
              })
            )
          );
        } else {
          dispatch(setOrderItems([...orderItems, order_item]));
        }
        setSending(false);
      })
      .catch((err) => {
        let error_message = "Something went wrong!";
        try {
          let error_object = JSON.parse(err.request.response);
          error_message = error_object.message;
        } catch {}

        setSending(false);

        dispatch(
          setNotify({
            open: true,
            action: error_message,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  const removeFromCart = (variantId) => {
    // const variantId = itemVariants[0].id;
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/customer-orders/remove-from-cart/`,
      headers: getAuthorizationHeader(restaurantId),
      data: {
        variant_id: variantId,
      },
    })
      .then((res) => {
        const { order_item } = res.data;

        if (order_item.qty >= 1) {
          const orderItemFound = orderItems.filter(
            (i) => i.id === order_item.id
          );
          if (orderItemFound.length) {
            dispatch(
              setOrderItems(
                orderItems.map((i) => {
                  if (i.id === order_item.id) {
                    return order_item;
                  }
                  return i;
                })
              )
            );
          } else {
            dispatch(setOrderItems([...orderItems, order_item]));
          }
        } else {
          dispatch(
            setOrderItems(orderItems.filter((i) => i.id !== order_item.id))
          );
        }

        setSending(false);
      })
      .catch((err) => {
        let error_message = "Something went wrong!";
        try {
          let error_object = JSON.parse(err.request.response);
          error_message = error_object.message;
        } catch {}

        setSending(false);

        dispatch(
          setNotify({
            open: true,
            action: error_message,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return loaded ? (
    <Stack direction="row" spacing={1} alignItems="center">
      {itemVariants.length > 1 ? (
        <CartOperationVariants
          item={item}
          optionCount={optionCount}
          order={order}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          sending={sending}
          inCartCount={inCartCount}
          itemVariants={itemVariants}
        />
      ) : inCartCount ? (
        <>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={() => removeFromCart(itemVariants[0].id)}
            disabled={sending}
          >
            <Remove />
          </IconButton>
          <Typography
            level="body-xs"
            minWidth="16px"
            textAlign="center"
            fontWeight={700}
          >
            {inCartCount}
          </Typography>
          <IconButton
            size="sm"
            variant="outlined"
            onClick={() => addToCart(itemVariants[0].id)}
            disabled={sending}
          >
            <Add />
          </IconButton>
        </>
      ) : (
        <Button
          size="sm"
          color="neutral"
          startDecorator={<Add />}
          onClick={() => addToCart(itemVariants[0].id)}
          disabled={sending}
        >
          ADD
        </Button>
      )}
    </Stack>
  ) : null;
}
