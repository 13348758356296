import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/joy";

import Category from "./category";
import { useCallback, useEffect, useState } from "react";

export default function CategoryList({ categoryRefs }) {
  const { itemCategories, items, variants, wishlist, filters } = useSelector(
    (state) => state.restaurant
  );

  const [loaded, setLoaded] = useState(false);
  const [categories, setCategories] = useState([]);

  const haveItems = useCallback(
    (category) => {
      let filteredItems = items.filter((item) => item.category === category.id);
      if (filters.isVeg === true) {
        filteredItems = filteredItems.filter((i) => i.is_veg);
      } else if (filters.isVeg === false) {
        filteredItems = filteredItems.filter((i) => !i.is_veg);
      }
      if (filters.wishlistedOnly) {
        const wishlistedVariants = variants.filter((i) =>
          wishlist.includes(i.id)
        );
        const tempWishlistedItemIds = wishlistedVariants.map((i) => i.item);
        const wishlistedItemIds = [...new Set(tempWishlistedItemIds)];

        filteredItems = filteredItems.filter((i) =>
          wishlistedItemIds.includes(i.id)
        );
      }
      return Boolean(filteredItems.length);
    },
    [filters.isVeg, filters.wishlistedOnly, items, variants, wishlist]
  );

  useEffect(() => {
    let categories = [];
    itemCategories.forEach((cat) => {
      if (haveItems(cat)) {
        categories = [...categories, cat];
      }
    });
    setCategories(categories.sort((a, b) => a.seq_no - b.seq_no));
    setLoaded(true);
  }, [loaded, itemCategories, haveItems]);

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: "#f6f6f6",
      }}
    >
      {categories.length ? (
        categories.map((category, index) => (
          <Category
            key={index}
            category={category}
            categoryRef={(el) => (categoryRefs.current[index] = el)}
          />
        ))
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
          bgcolor="#fff"
        >
          <Typography level="body-xs">
            {filters.wishlistedOnly
              ? "You haven't wishlisted anything!"
              : "Menu is not available!"}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
