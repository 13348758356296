import React from "react";

import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/joy";

import logo_placeholder from "../../assets/images/restaurant.png";

const DEFAULT_IMAGE = logo_placeholder;

export default function BrandingSm() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { restaurant, table, session } = useSelector(
    (state) => state.restaurant
  );

  const getRestaurantLogo = () => {
    if (restaurant.logo) {
      return restaurant.logo;
    }
    return DEFAULT_IMAGE;
  };
  return (
    <Stack direction="row" spacing={1.5}>
      <img
        src={getRestaurantLogo()}
        alt="logo"
        style={{
          borderRadius: "50%",
          height: "64px",
          width: "64px",
          border: "1px solid #e5e5e5",
        }}
      />
      <Stack justifyContent="center">
        <Typography level="body-sm" fontWeight={700} component="h1">
          {restaurant.name}
        </Typography>
        {isAuthenticated && table && session ? (
          <Typography level="body-xs" textColor="neutral.400">
            {table.name} (<b>{session.booking_code}</b>)
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
}
