import { useDispatch, useSelector } from "react-redux";
import { resetNotify } from "../../redux/utils";

import { Container, Snackbar } from "@mui/joy";

export default function Wrapper({ children }) {
  const { notify } = useSelector((state) => state.utils);

  const dispatch = useDispatch();

  return (
    <Container
      maxWidth="xs"
      sx={{
        padding: "0px !important",
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        color={notify.severity || "success"}
        size="sm"
        autoHideDuration={notify.autoHideDuration}
        variant="outlined"
        onClose={() => {
          dispatch(resetNotify());
        }}
      >
        {notify.action}
      </Snackbar>
    </Container>
  );
}
