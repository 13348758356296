import { useEffect, useState } from "react";
import Menu from "../../menu";
import { useSelector } from "react-redux";
import { checkIfSubscribedTo, PLAN } from "../../../../utils/config";
import { useNavigate } from "react-router-dom";

export default function Table() {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      if (!checkIfSubscribedTo(restaurant.plan_code, PLAN.PRO)) {
        navigate(`/${restaurant.uid}/menu`);
      }
      setLoaded(true);
    }
  }, [loaded, restaurant.plan_code, navigate, restaurant.uid]);

  return loaded ? <Menu /> : null;
}
